import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export const consortiumChains = [
  // {
  //   key: 'ant',
  //   name: 'AntChain',
  //   link: '/createTempProject/ant',
  //   title: 'Ant Financial OpenChain',
  //   titleZH: '蚂蚁区块链开放联盟链',
  //   contentZH:
  //     '蚂蚁区块链开放联盟链是一个低成本、低门槛、开放普惠的区块链服务网络，依赖蚂蚁区块链联盟链强大的技术基础、创新公有许可机制，联合各行业权威节点合作伙伴共同打造。开放联盟链致力于解决现有区块链规模化应用难题，本着生态共建，合作共赢的原则，向广大开发者开放该服务网络，赋能全行业开发者，共建生态繁荣。',
  //   content:
  //     "Ant Financial OpenChain (AntChain) is an open BaaS platform based on Ant Financial and Alibaba Cloud's private blockchain. It is a basic blockchain solution in the industry. Ant Blockchain BaaS aims to build an open collaboration platform, which can provide convenient services for enterprises and individuals around the world and bring more equal opportunities.",
  //   screenshot: (
  //     <StaticImage
  //       src="../../images/screenshot/ant.png"
  //       placeholder="blur"
  //       alt="AntChain screenshot"
  //       width={1894}
  //       objectFit="contain"
  //     />
  //   ),
  //   img: (
  //     <StaticImage
  //       src="../../images/chains/ant.svg"
  //       placeholder="blur"
  //       alt="AntChain Logo"
  //       width={36}
  //       objectFit="contain"
  //     />
  //   ),
  //   largeImg: (
  //     <StaticImage
  //       src="../../images/chains/ant.svg"
  //       placeholder="blur"
  //       alt="AntChain Logo"
  //       width={145}
  //       objectFit="contain"
  //     />
  //   )
  // },
  // {
  //   key: 'filoop',
  //   name: 'Filoop',
  //   link: 'https://filoopide.com',
  //   title: 'Filoop',
  //   titleZH: '飞洛区块链',
  //   contentZH:
  //     '飞洛区块链开放服务平台是趣链科技为企业和开发者推出的区块链在线服务平台，提供专业、全面、国际领先的区块链技术服务。 平台支持区块链框架在云、物理机的多种自动化部署模式，极大提高资源使用的灵活性并降低开发的成本。 平台已成功服务超过三十家大中型金融机构与大型企业，为区块链+生态提供全流程场景搭建和技术赋能。',
  //   content:
  //     'Filoop Blockchain Open Service Platform is a blockchain online service platform launched by Hyperchain Technology for enterprises and developers, providing professional, comprehensive and internationally leading blockchain technology services. The platform supports a variety of automated deployment models of blockchain frameworks in the cloud and physical machines, greatly increasing the flexibility of resource usage and reducing development cost.',
  //   screenshot: (
  //     <StaticImage
  //       src="../../images/screenshot/filoop.jpg"
  //       placeholder="blur"
  //       alt="Filoop screenshot"
  //       width={1894}
  //       objectFit="contain"
  //     />
  //   ),
  //   img: (
  //     <StaticImage
  //       src="../../images/chains/filoop.png"
  //       placeholder="blur"
  //       alt="Filoop Logo"
  //       width={36}
  //       objectFit="contain"
  //     />
  //   ),
  //   largeImg: (
  //     <StaticImage
  //       src="../../images/chains/filoop.png"
  //       placeholder="blur"
  //       alt="Filoop Logo"
  //       width={145}
  //       objectFit="contain"
  //     />
  //   ),
  // },
  {
    key: 'fisco',
    name: 'FISCO BCOS',
    link: '/createTempProject/fisco',
    title: 'FISCO BCOS',
    titleZH: 'FISCO BCOS',
    contentZH:
      'FISCO BCOS (Be Credible, Open & Secure) 是完全开源的联盟区块链底层技术平台，由金融区块链合作联盟（深圳）（简称金链盟）成立开源工作组通力打造。开源工作组成员包括博彦科技、华为、深证通、神州数码、四方精创、腾讯、微众银行、亦笔科技和越秀金科等金链盟成员机构。',
    content:
      'FISCO BCOS (Be Credible, Open & Secure) platform is collaboratively built by the FISCO open source working group. Members in the working group include Beyondsoft, Digital China, Forms Syntron, Huawei, Shenzhen Securities Communications, Tencent, Webank and more.',
    screenshot: (
      <StaticImage
        src="../../images/screenshot/fisco.png"
        placeholder="blur"
        alt="Fisco screenshot"
        width={1894}
        objectFit="contain"
      />
    ),
    img: (
      <StaticImage
        src="../../images/chains/fisco_white.svg"
        placeholder="blur"
        alt="Fisco Logo"
        width={36}
        objectFit="contain"
      />
    ),
    largeImg: (
      <StaticImage
        src="../../images/chains/fisco_white.svg"
        placeholder="blur"
        alt="Fisco Logo"
        width={125}
        objectFit="contain"
      />
    )
  },
  {
    key: 'aptos',
    name: 'Aptos',
    link: '/createTempProject/aptos',
    title: `Aptos`,
    titleZH: 'Aptos',
    contentZH:
      'Aptos 是由前 Facebook 员工开发的创新型公共区块链（权益证明），专注于为使用 Move 编程语言开发的智能合约提供高吞吐量和强大安全性。',
    content: `Aptos is an innovative public blockchain (proof of stake) developed by former Facebook employees, with a primary focus on delivering high throughput and robust security for smart contracts developed using the Move programming language.`,
    screenshot: (
      <StaticImage
        src="../../images/screenshot/aptos.png"
        placeholder="blur"
        alt="Aptos screenshot"
        width={1894}
        objectFit="contain"
      />
    ),
    img: (
      <StaticImage
        src="../../images/chains/aptos.svg"
        placeholder="blur"
        alt="Aptos Logo"
        width={36}
        objectFit="contain"
      />
    ),
    largeImg: (
      <StaticImage
        src="../../images/chains/aptos@2x.svg"
        placeholder="blur"
        alt="Aptos Logo"
        width={145}
        objectFit="contain"
      />
    )
  },
  {
    key: 'sui',
    name: 'SUI',
    link: '/createTempProject/sui',
    title: `SUI`,
    titleZH: 'SUI',
    contentZH:
      'Sui 是一条第一层区块链，旨在使数字资产的所有权变得快速、私密、安全且对每个人都可访问。',
    content: `Layer 1 blockchain designed to make digital asset ownership fast, private, secure, and accessible to everyone.`,
    screenshot: (
      <StaticImage
        src="../../images/screenshot/sui.png"
        placeholder="blur"
        alt="SUI screenshot"
        width={1894}
        objectFit="contain"
      />
    ),
    img: (
      <StaticImage
        src="../../images/chains/sui.png"
        placeholder="blur"
        alt="Aptos Logo"
        width={36}
        objectFit="contain"
      />
    ),
    largeImg: (
      <StaticImage
        src="../../images/chains/sui.png"
        placeholder="blur"
        alt="Aptos Logo"
        width={145}
        objectFit="contain"
      />
    )
  },
  {
    key: 'dfinity',
    name: 'Internet Computer',
    link: '/createTempProject/dfinity',
    title: 'Internet Computer',
    titleZH: 'Internet Computer',
    contentZH:
      'Internet Computer 为公共互联网增加了自治的无服务器云功能，使得可以在去中心化网络上通过“canister软件”完全构建任何系统或服务。',
    content:
      'The Internet Computer adds autonomous serverless cloud functionality to the public Internet – making it possible to build almost any system or service entirely on a decentralized network using “canister software”.',
    screenshot: (
      <StaticImage
        src="../../images/screenshot/dfinity.png"
        placeholder="blur"
        alt="Dfinity screenshot"
        width={1894}
        objectFit="contain"
      />
    ),
    img: (
      <StaticImage
        src="../../images/chains/dfinity.png"
        placeholder="blur"
        alt="Dfinity Logo"
        width={38}
        objectFit="contain"
      />
    ),
    largeImg: (
      <StaticImage
        src="../../images/chains/dfinity.png"
        placeholder="blur"
        alt="Dfinity Logo"
        width={145}
        objectFit="contain"
      />
    )
  },
  {
    key: 'flow',
    name: 'Flow',
    link: '/createTempProject/flow',
    title: `Flow`,
    titleZH: 'Flow',
    contentZH:
      '福洛链（flow）为下一代应用程序、游戏以及数字资产而构建的新区块链。',
    content: `Flow is a new blockchain built for the next generation of apps, games, and the digital assets that power them.`,
    screenshot: (
      <StaticImage
        src="../../images/screenshot/flow.png"
        placeholder="blur"
        alt="Flow screenshot"
        width={1894}
        objectFit="contain"
      />
    ),
    img: (
      <StaticImage
        src="../../images/chains/flow@2x.png"
        placeholder="blur"
        alt="Flow Logo"
        width={36}
        objectFit="contain"
      />
    ),
    largeImg: (
      <StaticImage
        src="../../images/chains/flow@2x.png"
        placeholder="blur"
        alt="Flow Logo"
        width={145}
        objectFit="contain"
      />
    )
  }

  // {
  //   key: 'diem',
  //   name: 'Diem',
  //   link: 'https://libraide.com',
  //   title: 'Diem',
  //   titleZH: 'Diem',
  //   contentZH:
  //     'Diem致力于为数十亿人建立一个简明的全球性货币金融基础体系。Diem区块链的目标是为数十亿人提供金融服务的基础设施，其中也包括新的全球货币的。Diem区块链从建造之初，就以可扩展性，安全性，存储及吞吐的效率，和未来的可变性为优先考量。',
  //   content:
  //     'Diem is a simple global currency and financial infrastructure that empowers billions of people.',
  //   screenshot: (
  //     <StaticImage
  //       src="../../images/screenshot/libra.jpg"
  //       placeholder="blur"
  //       alt="Diem screenshot"
  //       width={1894}
  //       objectFit="contain"
  //     />
  //   ),
  //   img: (
  //     <StaticImage
  //       src="../../images/chains/libra.png"
  //       placeholder="blur"
  //       alt="Diem Logo"
  //       width={36}
  //       objectFit="contain"
  //     />
  //   ),
  //   largeImg: (
  //     <StaticImage
  //       src="../../images/chains/libra.png"
  //       placeholder="blur"
  //       alt="Diem Logo"
  //       width={145}
  //       objectFit="contain"
  //     />
  //   )
  // },
  // {
  //   key: 'chain33',
  //   name: 'Chain33',
  //   link: '/createTempProject/chain33',
  //   title: 'Chain33',
  //   titleZH: 'Chain33',
  //   contentZH:
  //     'Chain33是由复杂美自主研发的区块链底层开发平台，于2018年在github上开源。 Chain33平台是一套支持共识，数据库，执行器等可插拔，且易升级的区块链架构。 Chain33创造性的支持分层的架构，主链负责交易清算，智能合约和虚拟机从主链上分离放到平行链上独立执行，多条平行链并存提升运算效率。且平行链之间通过主链实现链间互联。',
  //   content:
  //     'Chain33 platform is a pluggable and easily upgraded blockchain architecture that supports consensus, database, executor, etc. Chain33 creatively supports a layered architecture, where the main chain is responsible for transaction clearing, intelligent contracts and virtual machines are separated from the main chain and executed independently on parallel chains, and multiple parallel chains coexist to improve computing efficiency. And the parallel chains are interconnected through the main chain.',
  //   screenshot: (
  //     <StaticImage
  //       src="../../images/screenshot/chain33.png"
  //       placeholder="blur"
  //       alt="Chain33 screenshot"
  //       width={1894}
  //       objectFit="contain"
  //     />
  //   ),
  //   img: (
  //     <StaticImage
  //       src="../../images/chains/chain33.png"
  //       placeholder="blur"
  //       alt="Chain33 Logo"
  //       width={36}
  //       objectFit="contain"
  //     />
  //   ),
  //   largeImg: (
  //     <StaticImage
  //       src="../../images/chains/chain33.png"
  //       placeholder="blur"
  //       alt="Chain33 Logo"
  //       width={145}
  //       objectFit="contain"
  //     />
  //   )
  // },
];

export const consortiumChains2 = [
  {
    key: 'fisco',
    name: 'FISCO BCOS',
    link: '/createTempProject/fisco',
    title: 'FISCO BCOS',
    titleZH: 'FISCO BCOS',
    contentZH:
      'FISCO BCOS (Be Credible, Open & Secure) 是完全开源的联盟区块链底层技术平台，由金融区块链合作联盟（深圳）（简称金链盟）成立开源工作组通力打造。开源工作组成员包括博彦科技、华为、深证通、神州数码、四方精创、腾讯、微众银行、亦笔科技和越秀金科等金链盟成员机构。',
    content:
      'FISCO BCOS (Be Credible, Open & Secure) platform is collaboratively built by the FISCO open source working group. Members in the working group include Beyondsoft, Digital China, Forms Syntron, Huawei, Shenzhen Securities Communications, Tencent, Webank and more.',
    screenshot: (
      <StaticImage
        src="../../images/screenshot/fisco.png"
        placeholder="blur"
        alt="Fisco screenshot"
        width={1894}
        objectFit="contain"
      />
    ),
    img: (
      <StaticImage
        src="../../images/chains/fisco_white.svg"
        placeholder="blur"
        alt="Fisco Logo"
        width={36}
        objectFit="contain"
      />
    ),
    largeImg: (
      <StaticImage
        src="../../images/chains/fisco_white.svg"
        placeholder="blur"
        alt="Fisco Logo"
        width={125}
        objectFit="contain"
      />
    )
  }
];

export const publicChains = [
  {
    key: 'bnb chain',
    name: 'BNB Chain',
    link: '/createTempProject/binance',
    title: 'BNB Chain',
    titleZH: 'BNB Chain',
    contentZH:
      'BNB Chain是一种创新的解决方案，可为Binance Chain带来可编程性和互操作性。币安智能链依靠21个验证器组成的系统，该验证器具有权益证明证明（PoSA）共识，可支持较短的冻结时间和较低的费用。权益最强的验证者候选人将成为验证者并产生区块。双符号检测和其他斜线逻辑可确保安全性，稳定性和链确定性。',
    content:
      'BNB Smart Chain is an innovative solution to bring programmability and interoperability to Beacon Chain. BNB Smart Chain relies on a system of 21 active validators with Proof of Staked Authority (PoSA) consensus that can support short block time and lower fees. The most bonded validator candidates of staking will become validators and produce blocks. The double-sign detection and other slashing logic guarantee security, stability, and chain finality.',
    screenshot: (
      <StaticImage
        src="../../images/screenshot/binance.png"
        placeholder="blur"
        alt="Binance screenshot"
        width={1894}
        objectFit="contain"
      />
    ),
    img: (
      <StaticImage
        src="../../images/chains/binance.png"
        placeholder="blur"
        alt="Binance Logo"
        width={36}
        objectFit="contain"
      />
    ),
    largeImg: (
      <StaticImage
        src="../../images/chains/binance.png"
        placeholder="blur"
        alt="Binance Logo"
        width={145}
        objectFit="contain"
      />
    )
  },
  {
    key: 'ethereum',
    name: 'Ethereum',
    link: '/createTempProject/ethereum',
    title: 'Ethereum',
    titleZH: '以太坊',
    contentZH:
      '以太坊是一个为去中心化应用（dApp）而生的全球开源平台。在以太坊上，你可以通过编写代码管理数字资产、运行程序。',
    content:
      'Ethereum is a global, open-source platform for decentralized applications. On Ethereum, you can write code that controls digital value, runs exactly as programmed, and is accessible anywhere in the world.',
    screenshot: (
      <StaticImage
        src="../../images/screenshot/ethereum.png"
        placeholder="blur"
        alt="Ethereum screenshot"
        width={1894}
        objectFit="contain"
      />
    ),
    img: (
      <StaticImage
        src="../../images/chains/ethereum.png"
        placeholder="blur"
        alt="Ethereum Logo"
        width={22}
        objectFit="contain"
      />
    ),
    largeImg: (
      <StaticImage
        src="../../images/chains/ethereum.png"
        placeholder="blur"
        alt="Ethereum Logo"
        width={75}
        objectFit="contain"
      />
    )
  },
  {
    key: 'conflux',
    name: 'Conflux',
    link: '/createTempProject/conflux',
    title: 'Conflux',
    titleZH: 'Conflux',
    contentZH:
      'Conflux是一个跨协议和边界扩展去中心化经济体。Conflux Network 使资产和数据的安全和可互操作的流动成为可能，从而为所有人创造价值互联网',
    content:
      'Conflux is a scaling decentralized economies across protocols and borders. Conflux Network enables the secure and interoperable flow of assets and data to create an internet of value for all.',
    screenshot: (
      <StaticImage
        src="../../images/screenshot/conflux.png"
        placeholder="blur"
        alt="Conflux screenshot"
        width={1894}
        objectFit="contain"
      />
    ),
    img: (
      <StaticImage
        src="../../images/chains/conflux.png"
        placeholder="blur"
        alt="Conflux Logo"
        width={32}
        objectFit="contain"
      />
    ),
    largeImg: (
      <StaticImage
        src="../../images/chains/conflux.png"
        placeholder="blur"
        alt="Conflux Logo"
        width={105}
        objectFit="contain"
      />
    )
  },
  {
    key: 'iris',
    name: 'IRISnet',
    link: '/createTempProject/iris',
    title: 'IRISnet',
    titleZH: 'IRISnet',
    contentZH:
      'IRISnet（又称IRIS Hub）旨在成为下一代分布式应用程序的基础。借由Cosmos-SDK构建，IRIS Hub通过统一的服务模型实现跨链互操作性，同时提供各种模块以支持DeFi（去中心化金融）应用。',
    content:
      'IRISnet (a.k.a IRIS Hub) is designed to be the foundation for the next generation distributed applications. Built with Cosmos-SDK, IRIS Hub enables cross-chain interoperability through a unified service model, while providing a variety of modules to support DeFi applications.',
    screenshot: (
      <StaticImage
        src="../../images/screenshot/iris.PNG"
        placeholder="blur"
        alt="IRISnet screenshot"
        width={1894}
        objectFit="contain"
      />
    ),
    img: (
      <StaticImage
        src="../../images/chains/iris.png"
        placeholder="blur"
        alt="IRISNet Logo"
        width={32}
        objectFit="contain"
      />
    ),
    largeImg: (
      <StaticImage
        src="../../images/chains/iris.png"
        placeholder="blur"
        alt="IRISnet Logo"
        width={105}
        objectFit="contain"
      />
    )
  },

  {
    key: 'nervos',
    name: 'Nervos Network',
    link: '/createTempProject/nervos',
    title: `Nervos Network`,
    titleZH: 'Nervos Network',
    contentZH:
      'Godwoken 是 Nervos Layer-2 的关键组件，与 Rollup 技术配合使用，创建了一个可扩展的、与以太坊虚拟机（EVM）兼容的层，用于 Nervos 网络。',
    content: `Godwoken is a key component of Nervos' layer-2, working in conjunction with the Rollup technique to create a scalable, EVM-compatible layer for the Nervos network. With Godwoken, developers can build decentralized applications (dApps) on the Ethereum Virtual Machine (EVM) and run them on the Nervos network, increasing the scalability and efficiency of the network.`,
    screenshot: (
      <StaticImage
        src="../../images/screenshot/nervos.png"
        placeholder="blur"
        alt="Nervos screenshot"
        width={1894}
        objectFit="contain"
      />
    ),
    img: (
      <StaticImage
        src="../../images/chains/nervos.png"
        placeholder="blur"
        alt="Nervos Logo"
        width={36}
        objectFit="contain"
      />
    ),
    largeImg: (
      <StaticImage
        src="../../images/chains/nervos.png"
        placeholder="blur"
        alt="Nervos Logo"
        width={145}
        objectFit="contain"
      />
    )
  }
];

export const publicChains2 = [
  // {
  //   key: 'iost',
  //   name: 'IOST',
  //   link: 'https://iost.chainide.com',
  //   title: `IOST`,
  //   titleZH: 'IOST',
  //   contentZH:
  //     'IOST是一个高性能区块链公链项目。通过独创的PoB（置信度证明）共识机制，项目实现了高层次的扩展性和去中心化。并致力于将区块链技术应用 推广至B端各类行业和C端各类应用场景中。ChainIDE是首个支持IOST的云端IDE，已经帮助IOST辅助编译服务了超5万份智能合约。',
  //   content: `IOST is a fast, decentralised blockchain network based on the next-generation consensus algorithm “Proof of Believability” (PoB). IOST's mission is to be the underlying architecture for online services that meets the security and scalability needs of a decentralised economy.`,
  //   screenshot: (
  //     <StaticImage
  //       src="../../images/screenshot/iost.jpg"
  //       placeholder="blur"
  //       alt="IOST screenshot"
  //       width={1894}
  //       objectFit="contain"
  //     />
  //   ),
  //   img: (
  //     <StaticImage
  //       src="../../images/chains/iost.png"
  //       placeholder="blur"
  //       alt="Iost Logo"
  //       width={36}
  //       objectFit="contain"
  //     />
  //   ),
  //   largeImg: (
  //     <StaticImage
  //       src="../../images/chains/iost.png"
  //       placeholder="blur"
  //       alt="Iost Logo"
  //       width={145}
  //       objectFit="contain"
  //     />
  //   )
  // },
  {
    key: 'polygon',
    name: 'Polygon',
    link: '/createTempProject/polygon',
    title: `Polygon`,
    titleZH: 'Polygon',
    contentZH:
      'Polygon 是一种用于构建和连接与以太坊兼容的区块链网络的协议框架。聚合了以太坊上可扩展解决方案，以支持以太坊多链生态系统。',
    content: `Polygon is a protocol and a framework for building and connecting Ethereum-compatible blockchain networks. Aggregating scalable solutions on Ethereum supporting a multi-chain Ethereum ecosystem.`,
    screenshot: (
      <StaticImage
        src="../../images/screenshot/polygon.png"
        placeholder="blur"
        alt="Polygon screenshot"
        width={1894}
        objectFit="contain"
      />
    ),
    img: (
      <StaticImage
        src="../../images/chains/polygon.png"
        placeholder="blur"
        alt="Polygon Logo"
        width={36}
        objectFit="contain"
      />
    ),
    largeImg: (
      <StaticImage
        src="../../images/chains/polygon.png"
        placeholder="blur"
        alt="Polygon Logo"
        width={145}
        objectFit="contain"
      />
    )
  },
  {
    key: 'xdc',
    name: 'XDC Network',
    link: '/createTempProject/xdc',
    title: `XDC Network`,
    titleZH: 'XDC Network',
    contentZH:
      'XDC Network 是一个全球性的、开源的、委托权益证明共识网络（XDPoS），具有几乎零的手续费、2秒区块确认时间，并且与ISO 20022金融消息标准具备互操作性。',
    content: `The XDC Network is a global, open-source, delegated proof of stake consensus network (XDPoS), with near-zero gas fees, 2 second block finality, and interoperability with ISO 20022 financial messaging standards. `,
    screenshot: (
      <StaticImage
        src="../../images/screenshot/xdc.jpg"
        placeholder="blur"
        alt="XDC screenshot"
        width={1894}
        objectFit="contain"
      />
    ),
    img: (
      <StaticImage
        src="../../images/chains/xdc.svg"
        placeholder="blur"
        alt="XDC Logo"
        width={36}
        objectFit="contain"
      />
    ),
    largeImg: (
      <StaticImage
        src="../../images/chains/xdc.svg"
        placeholder="blur"
        alt="XDC Logo"
        width={145}
        objectFit="contain"
      />
    )
  },
  {
    key: 'arb',
    name: 'Arbitrum',
    link: '/createTempProject/arbitrum',
    title: `Arbitrum`,
    titleZH: 'Arbitrum',
    contentZH:
      'Arbitrum 是一个使用 Arbitrum Nitro 技术栈构建的以太坊二层扩展技术套件，其中包括 Arbitrum One（Arbitrum Rollup 协议的实时实现）和 Arbitrum Nova（Arbitrum AnyTrust 协议的实时实现）。',
    content: `Arbitrum, a suite of Ethereum layer-2 scaling technologies built with the Arbitrum Nitro tech stack that includes Arbitrum One (a live implementation of the Arbitrum Rollup Protocol) and Arbitrum Nova (a live implementation of the Arbitrum AnyTrust Protocol).`,
    screenshot: (
      <StaticImage
        src="../../images/screenshot/arbitrum.png"
        placeholder="blur"
        alt="Arbitrum screenshot"
        width={1894}
        objectFit="contain"
      />
    ),
    img: (
      <StaticImage
        src="../../images/chains/arb.svg"
        placeholder="blur"
        alt="Arbitrum Logo"
        width={36}
        objectFit="contain"
      />
    ),
    largeImg: (
      <StaticImage
        src="../../images/chains/arb.svg"
        placeholder="blur"
        alt="Arbitrum Logo"
        width={145}
        objectFit="contain"
      />
    )
  },
  {
    key: 'astar-evm',
    name: 'Astar EVM',
    link: '/createTempProject/astar-evm',
    title: `Astar EVM`,
    titleZH: 'Astar EVM',
    contentZH:
      'Astar Network是Polkadot生态系统中最灵活的智能合约平台，具备Wasm和EVM智能合约环境，以及由Polkadot XCM和独特的交叉虚拟机（XVM）驱动的多链互操作性。',
    content: `Astar Network is the most flexible smart contract platform in the Polkadot ecosystem, featuring both Wasm and EVM environments, and multichain-native interoperability powered by Polkadot XCM and our unique Cross-Virtual Machine (XVM).`,
    screenshot: (
      <StaticImage
        src="../../images/screenshot/astar-evm.png"
        placeholder="blur"
        alt="astar evm screenshot"
        width={1894}
        objectFit="contain"
      />
    ),
    img: (
      <StaticImage
        src="../../images/chains/astar.svg"
        placeholder="blur"
        alt="Astar Logo"
        width={36}
        objectFit="contain"
      />
    ),
    largeImg: (
      <StaticImage
        src="../../images/chains/astar.svg"
        placeholder="blur"
        alt="Astar Logo"
        width={145}
        objectFit="contain"
      />
    )
  },
  {
    key: 'astar-wasm',
    name: 'Astar WASM',
    link: '/createTempProject/astar-wasm',
    title: `Astar WASM`,
    titleZH: 'Astar WASM',
    contentZH:
      'Astar Network是Polkadot生态系统中最灵活的智能合约平台，具备Wasm和EVM智能合约环境，以及由Polkadot XCM和独特的交叉虚拟机（XVM）驱动的多链互操作性。',
    content: `Astar Network is the most flexible smart contract platform in the Polkadot ecosystem, featuring both Wasm and EVM environments, and multichain-native interoperability powered by Polkadot XCM and our unique Cross-Virtual Machine (XVM).`,
    screenshot: (
      <StaticImage
        src="../../images/screenshot/astar-wasm.png"
        placeholder="blur"
        alt="astar wasm screenshot"
        width={1894}
        objectFit="contain"
      />
    ),
    img: (
      <StaticImage
        src="../../images/chains/astar.svg"
        placeholder="blur"
        alt="Astar Logo"
        width={36}
        objectFit="contain"
      />
    ),
    largeImg: (
      <StaticImage
        src="../../images/chains/astar.svg"
        placeholder="blur"
        alt="Astar Logo"
        width={145}
        objectFit="contain"
      />
    )
  }
  // {
  //   key: 'cocos',
  //   name: 'CocosBcx',
  //   link: 'https://cocosbcxide.com',
  //   title: `Cocos-BCX`,
  //   titleZH: 'Cocos-BCX',
  //   contentZH:
  //     'Cocos-BCX下一代游戏数字经济平台。Cocos BlockChain Expedition，简称"Cocos-BCX"，它的目标是打造一个完整的多平台游戏运行环境，即为区块链游戏开发者提供开发过程中的便利性和完善性，同时也为区块链游戏用户带来全新的游戏体验和超越以往的游戏形态，所有在游戏内获得的资产将完全属于用户。',
  //   content: `Cocos-BCX is the platform for the next generation of digital game economy. Cocos-BCX ,fully named “Cocos BlockChain Expedition”, aims to create an integrated multi-platform runtime environment for games, providing developers with the convenience and completeness in game development, while bringing users a whole new gaming experience, unprecedented gaming status, and with all the assets obtained in the games being wholly owned by the users.`,
  //   screenshot: (
  //     <StaticImage
  //       src="../../images/screenshot/cocos.jpg"
  //       placeholder="blur"
  //       alt="Cocos screenshot"
  //       width={1894}
  //       objectFit="contain"
  //     />
  //   ),
  //   img: (
  //     <StaticImage
  //       src="../../images/chains/cocos.png"
  //       placeholder="blur"
  //       alt="CocosBcx Logo"
  //       width={36}
  //       objectFit="contain"
  //     />
  //   ),
  //   largeImg: (
  //     <StaticImage
  //       src="../../images/chains/cocos.png"
  //       placeholder="blur"
  //       alt="CocosBcx Logo"
  //       width={145}
  //       objectFit="contain"
  //     />
  //   )
  // },

  // {
  //   key: 'ultrain',
  //   name: 'Ultrain',
  //   link: 'https://ultrainide.com',
  //   title: `Ultrain`,
  //   titleZH: 'Ultrain',
  //   contentZH:
  //     '超脑链是超脑团队开发的一条具有信任机制应用的公链。Ultrain在实现分布式、高效、安全的底层技术创新同时打造出了好用且实惠的产品，为通用的广大企业和开发者提供支持，致力于成为全球领先的商业公链。',
  //   content: `Ultrain's goal is to a programmable business society. Ultrain is a leading public blockchain optimized for commercial use. We aim to build a general purpose blockchain offering cloud services and an operating system.`,
  //   screenshot: (
  //     <StaticImage
  //       src="../../images/screenshot/ultrain.jpg"
  //       placeholder="blur"
  //       alt="Ultrain screenshot"
  //       width={1894}
  //       objectFit="contain"
  //     />
  //   ),
  //   img: (
  //     <StaticImage
  //       src="../../images/chains/ultrain.png"
  //       placeholder="blur"
  //       alt="Ultrain Logo"
  //       width={36}
  //       objectFit="contain"
  //     />
  //   ),
  //   largeImg: (
  //     <StaticImage
  //       src="../../images/chains/ultrain.png"
  //       placeholder="blur"
  //       alt="Ultrain Logo"
  //       width={145}
  //       objectFit="contain"
  //     />
  //   )
  // }
];

export const chainList1 = [
  ...consortiumChains,
  ...consortiumChains.slice(0).map((c) => {
    return { ...c, key: `${c.key}_copy` };
  }),
  ...consortiumChains.slice(0).map((c) => {
    return { ...c, key: `${c.key}_copy1` };
  })
];
export const chainList2 = [
  ...publicChains,
  ...publicChains.slice(0).map((c) => {
    return { ...c, key: `${c.key}_copy` };
  }),
  ...publicChains.slice(0).map((c) => {
    return { ...c, key: `${c.key}_copy1` };
  })
];

export const chainList3 = [
  ...publicChains2,
  ...publicChains2.slice(0).map((c) => {
    return { ...c, key: `${c.key}_copy` };
  }),
  ...publicChains2.slice(0).map((c) => {
    return { ...c, key: `${c.key}_copy1` };
  })
];
